<template>

  <v-container>
    <row-header :msg="msgRowHeader" :action="msgRowHeader.actionName"></row-header>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              item-key="name"
              class="elevation-1"
              loading
              loading-text="Loading... Please wait"
              v-show="loading"
          ></v-data-table>
          <v-data-table
              dense
              :headers="headers"
              :items="categories"
              :footer-props="rowsPerPageItems"
              :items-per-page="pagination"
              item-key="no"
              :search="search"
              v-show="!loading"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2 green--text"
                @click="editItem(item)"
                v-if="isAdmin"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                class="red--text"
                @click="deleteItem(item)"
                v-if="isAdmin"
              >
                mdi-delete
              </v-icon>
              <span v-if="!isAdmin">-</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <dialog-confirm></dialog-confirm>
  </v-container>

</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import DialogConfirm from "../../../components/content/DialogConfirm";
import { GetRequest, DeleteRequest } from '../../../store/services/users';

export default {
  components: {
    RowHeader,
    DialogConfirm,
  },
  data: () => ({
    search: null,
    rowsPerPageItems: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000]
    },
    pagination: 20,
    loading: true,
    msgRowHeader: {newUser: 'Create New', newMsg: 'Categories List', actionName: 'CategoryNew'},
    categories: [],
    headers: [
      { text: 'No', align: 'end', value: 'no'},
      { text: 'Code', value: 'code' },
      { text: 'Name', value: 'name' },
      { text: 'Created By', value: 'created_by' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Action', value: 'actions', sortable: false, },
    ],
    catSelected: {},
    isAdmin: false,
  }),
  async mounted() {
    console.log('view page admin categories');
    if (this.$store.getters.isLoggedIn) {
      await this.getCategoriesData();
      if (this.$store.getters.currentUser.role.level === 1) {
        this.isAdmin = true;
        await this.action.$on('delete-data-category', () => {
          this.deleteExecute();
        });
      } else {
        this.msgRowHeader = {newUser: '', newMsg: 'Categories List', actionName: 'non'};
      }
    }
  },
  beforeDestroy() {
    // this.action.$off('delete-data-user');
    // this.action.$off('open-dialog-conf-user');
  },
  methods : {
    async getCategoriesData() {
      await this.$store.dispatch('req_data');
      this.loading = this.$store.getters.isLoading;
      try{
        let response = await GetRequest('categories');
        this.categories = response.data;
        this.categories.forEach((val, i) => {
          val.no = i+1;
          val.created_at = val.created_at ? this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss') : '-';
        })
        await this.$store.dispatch('req_success');
        this.loading = this.$store.getters.isLoading;
        return true;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.loading = this.$store.getters.isLoading;
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    editItem (item) {
      this.catSelected = item;
      this.$router.push({name: 'CategoryEdit', params: item})
    },
    deleteItem (item) {
      this.catSelected = item;
      this.action.$emit('open-dialog-conf-category', true);
    },
    async deleteExecute () {
      // test dah
      await this.$store.dispatch('req_data')
      this.loading = this.$store.getters.isLoading;
      try {
        let response = await DeleteRequest('categories/' + this.catSelected._id);
        await this.$store.dispatch('req_success');
        this.loading = this.$store.getters.isLoading;
        // console.log(response);
        if (response.data && response.message === 'deleted') {
          alert('delete data success');
          await this.getCategoriesData();
          return;
        }
        alert('delete data failed');
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.loading = this.$store.getters.isLoading;
        alert('Delete data failed ' + e.message);
      }
    }
  }
}
</script>

<style scoped>

</style>
